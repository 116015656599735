import React from "react";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import { Link } from "gatsby";
import Article from "../components/Article/";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <div>
              <br />
              <h1> Hmm... ☹️ there’s nothing here. </h1>
              <br />
              <h3> Try one of these? 🤷🏾‍♂️</h3>
              <br />
              <Link to="/">
                <li> 🏠 Home </li>
              </Link>
              <Link to="/article/">
                <li> 📰 Articles </li>
              </Link>
            </div>
          </Article>
        )}
      </ThemeContext.Consumer>
      <Seo />
    </React.Fragment>
  );
};

export default NotFoundPage;
